<template>
    <div class="section">
        <page-title :title="title" class="ml-2"></page-title>
        <b-container>
            <b-card>
                <spinner v-if="refreshData"></spinner>
                <div v-if="!refreshData">
                    <custom-table
                        v-if="!refreshData"
                        :tableConfig="tableConfig"
                        :fields="fields"
                        :items="items"
                        :page="page"
                    ></custom-table>
                </div>
            </b-card>
        </b-container>
    </div>
</template>

<script>
import CustomTable from '@/components/shared/table/CustomTable';
import Spinner from '@/components/shared/element/Spinner';
import PageTitle from '@/layouts/components/PageTitle.vue';
import { TABLE } from '@/constants';
import { MetalRolloutsRepository } from '@/repositories';
import { EVENTS } from '@/constants/client/events';
import InputFilterEvents from '@/views/client/InputFilterEvents';

export default {
    name: 'ClientInterventionMetalRollouts',
    extends: InputFilterEvents,
    components: {
        PageTitle,
        CustomTable,
        Spinner
    },
    data() {
        return {
            title: this.$t('client.pages.interventionMetalRollouts.title'),
            items: [],
            tableConfig: {
                sortBy: 'created_at',
                striped: true,
                bordered: true,
                headVariant: 'dark',
                sortDesc: true,
                displayCount: true,
                currentPage: 1
            },
            fields: [
                {
                    key: 'site.name',
                    label: this.$t('client.pages.interventionMetalRollouts.table.columns.name'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'site.metal_rollout.length',
                    label: this.$t('client.pages.interventionMetalRollouts.table.columns.length'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'ref_client',
                    label: this.$t('client.pages.interventionMetalRollouts.table.columns.ref'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'status',
                    label: this.$t('client.pages.interventionMetalRollouts.table.columns.status'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'id',
                    label: this.$t('client.pages.interventionMetalRollouts.table.columns.id'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'alert_date',
                    label: this.$t('client.pages.interventionMetalRollouts.table.columns.alertDate'),
                    sortable: true,
                    class: 'text-left'
                },
                {
                    key: 'show_details',
                    label: this.$t('client.pages.interventionMetalRollouts.table.columns.actions'),
                    sortable: false,
                    class: 'text-center'
                }
            ],
            refreshData: true,
            page: TABLE.PAGE.CLIENT.INTERVENTION_METAL_ROLLOUTS.ID
        };
    },
    created() {
        this.getData();
        this.$on(EVENTS.DASHBOARD.INPUT_CLIENTS_FILTER_CHANGE, () => {
            this.getData();
        });
    },
    methods: {
        getData() {
            this.refreshData = true;
            MetalRolloutsRepository.getInterventionsMetalRollouts(this.getFilters())
                .then(response => {
                    console.log(response.data.data);
                    this.refreshData = false;
                    this.items = response.data.data;
                    this.items.map(item => {
                        if (item.status === null) {
                            item.status = '';
                        } else {
                            item.status = this.$t('client.pages.interventionList.interventionStatus.' + item.status);
                        }
                    });
                })
                .catch(error => {
                    this.refreshData = false;
                    console.log(error);
                });
        }
    }
};
</script>
